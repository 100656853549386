.trigsySection {
  margin-top: 32px;
  margin-left: 72px;
  position: absolute;
  display: flex;
  flex-direction: column;
}
#trigsy {
  color: var(--other-color-white, #fefefe);
  font-size: 30px;
  font-family: Jost;
  font-weight: 500;
  text-transform: capitalize;
  margin-left: 10px;
}
.trigsyLogoAndText {
  display: flex;
  flex-direction: row;
}
#footerTrigsySubHeading {
  display: flex;
  width: 247px;
  flex-direction: column;
  flex-shrink: 0;
  color: var(--other-color-white, #fefefe);
  font-size: 16px;
  font-family: Roboto;
  line-height: 24px;
  margin-top: 16px;
}

.socialMediaIcons {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}
#instagramId {
  margin-left: 16px;
  margin-right: 16px;
}
#linkedInId {
  margin-right: 16px;
}
#twitter {
  margin-right: 16px;
}
