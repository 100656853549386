#nameTextField {
  border-radius: 6px;
  border: 1px solid var(--primary-color-primary, #0052a8);
  display: flex;
  width: 450px;
  padding: 10.5px 16px;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 24px;
  margin-top: 32px;
  font-size: 16px;
  font-family: Roboto;
}
#emailTextField {
  border-radius: 6px;
  border: 1px solid var(--primary-color-primary, #0052a8);
  display: flex;
  border-color: #0052a8;
  width: 450px;
  padding: 10.5px 16px;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 24px;
  font-size: 16px;
  font-family: Roboto;
}
::placeholder {
  color: var(--typography-color-t-5, #bac2cc);
  font-size: 16px;
  font-family: Roboto;
}
#yourMessage {
  display: flex;
  width: 450px;
  height: 150px;
  padding: 16px;
  gap: 10px;
  margin-bottom: 24px;
  border-radius: 12px;
  font-family: Roboto;
  font-size: 16px;
  resize: none;
  border: 1px solid var(--primary-color-primary, #0052a8);
}

#submitBtn {
  color: white;
  display: inline-flex;
  padding: 8px 40px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  background: var(--primary-color-primary, #0052a8);
  cursor: pointer;
  font-size: 24px;
  font-family: Roboto;
  margin-left: 120px;
}
