.flutterThanksWebsite {
  font-family: Arial, sans-serif;
  text-align: center;
  color: black;
  padding: 20px;
  margin: 0;
  height: 95vh;
  background-color: white;
}

#thanksForSubmittingWebsiteHeading {
  font-size: 1rem;
  margin-top: 40px;
  margin-bottom: 20px;
}

#thanksForSubmittingWebsiteSubHeading {
  font-size: 1rem;
  text-align: center;
  margin-bottom: 10px;
  padding-top: 12px;
  justify-content: center;
  align-items: center;
  color: grey;
}
#noteText {
  font-weight: 500;
  text-decoration: underline;
  color: grey;
}
li {
  margin-top: 10px;
  text-align: start;
  color: grey;
  font-size: 8px;
}
.thanksSubHeading {
  margin-top: 150px;
  text-align: start;
}
#titleLogo {
  margin-top: 1px;
  position: relative;
  display: flex;
  align-items: start;
}
#doneBtn {
  font-weight: 700;
  color: #0052a8;
}
#illustration {
  margin-top: 90px;
  position: relative;
}
