#textField {
  border-radius: 6px;
  border: 1px solid var(--primary-color-primary, #0052a8);
  display: flex;
  width: 450px;
  padding: 10.5px 16px;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 24px;
  margin-top: 8px;
  font-size: 16px;
  font-family: Roboto;
}

::placeholder {
  color: var(--typography-color-t-5, #bac2cc);
  font-size: 16px;
  font-family: Roboto;
}

#submitBtn {
  color: white;
  display: inline-flex;
  padding: 8px 40px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  background: var(--primary-color-primary, #0052a8);
  cursor: pointer;
  font-size: 24px;
  font-family: Roboto;
}

.textFormFieldContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 100px;
}
.insideTextFormFieldContainer {
  padding: 3%;
  border-radius: 10px;
  box-shadow: 0 4px 8px 1px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
#salesPartnerId {
  position: relative;
  display: flex;
  font-size: 16px;
}
#dropdownList {
  border-radius: 6px;
  border: 1px solid var(--primary-color-primary, #0052a8);
  display: flex;
  width: 487px;
  padding: 10.5px 16px;
  margin-bottom: 24px;
  margin-top: 8px;
  font-size: 16px;
  font-family: Roboto;
}

@media (max-width: 600px) {
  #textField {
    width: 80%;
    margin-right: 0;
  }
  .insideTextFormFieldContainer {
    padding: 30px;
    margin-left: 0px;
    width: 74%;
    border-radius: 10px;
    box-shadow: 0 4px 8px 1px rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .textFormFieldContainer {
    margin-top: 60px;
  }
  #dropdownList {
    width: 90%;
  }
  #submitBtn{
    font-size: 20px;
  }
}
