.partnerUsefulLinks {
  margin-top: 42px;
  position: absolute;
  margin-left: 500px;
}
#partnerHowItWorksFooter {
  cursor: pointer;
}
#partnerFooterLinks:link {
  text-decoration: none;
}
#partnerFooterLinks:hover {
  text-decoration: underline;
  color: white;
}
