#howItWorksHeading {
  color: var(--typography-color-t-1, #0d386b);
  text-align: center;
  font-size: 36px;
  font-family: Roboto;
  font-weight: 500;
  position: relative;
  /* top: 140px; */
  top: 100px;
  left: 45vw;
  width: 241px;
}

.circleWithDottedLines {
  position: absolute;
  top: 162px;
  left: 50vw;
}
@media (max-width: 1600px) {
  .circleWithDottedLines {
    left: 700px;
  }
  #howItWorksHeading {
    left: 600px;
  }
}
