.downloadAppContainer {
  margin-top: 200px;
  border-radius: 12px;
  width: 1296px;
  height: 600px;
  margin-left: 10vw;
  background-image: url("../../../../assets/images/downloadOurApp/downloadAppBackground.svg");
}

#downloadAppHeading {
  color: #fdfdfd;
  font-size: 40px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 32px;
  display: flex;
  width: 337.163px;
  flex-direction: column;
  flex-shrink: 0;
  padding-top: 158px;
  padding-left: 82px;
}

#downloadAppSubHeading {
  color: var(--other-color-alert-actions, #fdfdfd);
  font-size: 20px;
  font-family: Roboto;
  line-height: 40px;
  display: flex;
  width: 546.75px;
  flex-direction: column;
  flex-shrink: 0;
  padding-top: 32px;
  padding-left: 82px;
}

#downloadPlayStoreImage {
  width: 198px;
  padding-left: 80px;
  padding-right: 41px;
}

.downloadAppImages {
  display: flex;
  padding-top: 40px;
  flex-direction: row;
}

.downloadAppContainer {
  display: flex;
  flex-direction: row;
  justify-self: center;
}
.downloadAppImage {
  padding-top: 60px;
  padding-left: 125px;
}
@media (max-width: 1000px) {
  .downloadAppContainer {
    margin-left: 70px;
  }
}
@media (max-width: 1600px) {
  .downloadAppContainer {
    margin-left: 50px;
  }
}
