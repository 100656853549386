@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap");
@import "https://fonts.googleapis.com/css?family=Jost";
/* body {
  margin: 0;
  padding: 0px;
  font-family: "Roboto";
  background-color: #fcfcfc;
} */
.NavbarItems section {
  /*Change this*/
  /* display: grid; */
  /* place-items: center; */
  /* height: 100%; */
  position: relative;
  z-index: 10;
}
section#about {
  height: 626px;
  margin-top: 0px;
  display: flex;
  position: relative;
}
section#howItWorks {
  height: 1000px;
  position: relative;
}
.customer-loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.834);
  z-index: 100;
}

.customer-spinner {
  width: 64px;
  height: 64px;
  border: 2px solid;
  border-color: #0052a8 transparent #0052a8 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

section#download {
  margin-top: 20px;
  /* position: relative; */
  /* margin-left: 72px; */
  left: 72px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* display: flex; */
  /* justify-content: center; */
  /* position: relative; */
}
#customerYoutubeVideo {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
#contact {
  margin-top: 64px;
  position: relative;
  width: 100%;
}
/* For Max width 1000px */

@media (max-width: 1000px) {
  section {
    font-size: 10px;
  }
  section#download {
    margin-top: -350px;
  }
  #contact {
    margin-top: -230px;
    position: relative;
  }
  section#about {
    margin-top: -30px;
    position: relative;
  }
  #customerYoutubeVideo {
    margin-top: -250px;
  }
}
