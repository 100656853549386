.container {
  margin: 0 auto;
  justify-content: flex-end;
  padding: 0 15px;
  display: flex;
  margin-right: 72px;
  height: 100%;
}

.navbar {
  width: 100%;
  /* width: calc(100rem - 81px); */
  position: fixed;
  height: 50px;
  top: 0px;
  z-index: 100;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #fefefe;
  /* z-index: 999; */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
}

.logo {
  margin-left: 72px;
  margin-top: 10px;
  position: absolute;
  width: 30.22px;
  height: 40px;
  left: 0px;
  top: 0px;
}
#trigsy-title {
  position: absolute;
  width: 51px;
  height: 29px;
  left: 39px;
  top: 6px;
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #0d386b;
}
.menu-icon {
  display: none;
}

#downloadBtn {
  padding: 8px 32px;
  color: white;
  background: #0d386b;
  border-radius: 24px;

  font-size: 16px;
  /* margin-bottom: -10px; */
  position: relative;
  margin-left: -90px;
  margin-right: 10px;
  margin-top: -5px;
}

.nav-elements {
  margin-top: 10px;
}

.nav-elements ul {
  display: flex;
  list-style-type: none;
  cursor: pointer;
  justify-content: flex-end;
}

#howItWorksId {
  width: 120px;
}
#partners {
  width: 120px;
  margin-right: 90px;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-style: normal;
  font-weight: 500;
  width: 117px;
  font-size: 20px;
  line-height: 23px;
  color: #0d386b;
  text-decoration: none;
}

/* .nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
} */

/* If Max width 1200px */

@media (max-width: 1200px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }

  #howItWorksId {
    width: 90px;
  }
  .nav-elements {
    font-size: 16px;
  }
  .navbar {
    width: 100%;
  }
  .nav-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    cursor: pointer;
  }
  .nav-elements ul a {
    font-size: 14px;
    overflow: auto;
    width: 117px;
  }
  #downloadBtn {
    margin-left: -100px;
    margin-right: -10px;
    font-size: 14px;
  }
}

/* If Max width 1000px */

@media (max-width: 1000px) {
  .menu-icon {
    display: flex;
    cursor: pointer;
    position: sticky;
    right: 0px;
    justify-content: flex-end;
    /* margin-left: 80%; */
    margin-top: 10px;
  }
  .nav-elements {
    margin-top: 60px;
    position: sticky;
    right: 0px;
    background-color: white;
    width: 0px;
    justify-content: flex-start;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }
  .nav-elements.active {
    width: 270px;
  }
  #downloadBtn {
    max-width: 90px;
  }
  .nav-elements ul {
    color: #0d386b;
    display: flex;
    margin-left: 10px;
    flex-direction: column;

    /* margin-right: 100px; */
  }
  .navbar {
    width: 100rem;
  }
  .nav-elements ul li {
    margin-right: unset;
    margin-top: 20px;
  }
  #downloadBtn {
    margin-left: 0px;
  }
  .container {
    /* max-width: 72px; */
    padding: 0px;
    margin: 0 auto;
    justify-content: flex-end;
    display: flex;
  }
}
