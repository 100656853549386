.line-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-line {
  border-left: 4px dashed #5c8dc1;
  height: 808px;
  margin: 0 10px;
}
