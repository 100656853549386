#contactUsHeading {
  color: var(--typography-color-t-1, #0d386b);
  font-size: 36px;
  font-family: Roboto;
  font-weight: 500;
  display: block;
  margin-left: 600px;
  width: 178px;
  top: 40px;
  position: relative;
}
.sendUsAMessage {
  display: flex;
  flex-direction: row;
  margin-left: 100px;
}
#sendUsText {
  color: #0d386b;
  font-size: 36px;
  font-family: Roboto;
  font-weight: 500;
}
#messageText {
  color: #0074ed;
  margin-left: 8px;
  font-size: 36px;
  font-family: Roboto;
  font-weight: 500;
}
.contactUsTextField {
  margin-left: 211px;
  margin-top: 100px;
}
#textFieldSubHeading {
  color: var(--typography-color-t-3, #647e9c);
  font-size: 20px;
  font-family: Roboto;
  margin-top: 16px;
  margin-left: 60px;
}

.contactUsBody {
  display: flex;
  flex-direction: row;
}
.contactUsTexts {
  margin-top: 240px;
  margin-left: 192px;
}
#emailUsText {
  margin-top: 32px;
  margin-bottom: 32px;
}
.contactUs {
  height: 741px;
  margin-left: 12vw;
}
@media (max-width: 1000px) {
  .contactUs {
    margin-top: 350px;
    margin-left: 90px;
  }
}
@media (max-width: 1600px) {
  .contactUs {
    margin-left: 0px;
  }
}
