#downloadImage1 {
  position: absolute;
  top: 220px;
  left: 33vw;
}
.downloadStep1 {
  position: absolute;
  top: 280px;
  left: 55vw;
}
#downloadApp {
  margin-bottom: 16px;
}
#appleImage {
  margin-left: 70px;
  margin-right: 16px;
}
@media (max-width: 1600px) {
  #downloadImage1 {
    position: absolute;
    top: 220px;
    left: 386px;
  }
  .downloadStep1 {
    position: absolute;
    top: 280px;
    left: 788px;
  }
  #downloadApp {
    margin-bottom: 16px;
  }
}