.circle-container {
  display: inline-block;
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #0052a8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.number {
  color: #fff;
  font-size: 18px;
}
