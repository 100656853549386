.partnerFooter {
  width: 100%;
  height: 285px;
  margin-top: 50px;
  background: var(--primary-color-primary, #0052a8);
  position: relative;
  z-index: 10;
}
.partnerFooterContent {
  margin-left: 10vw;
}
#partnercopyRight {
  position: relative;
  background: var(--primary-color-primary-color-2, #2e70b4);
  width: 100%;
  color: var(--other-color-white, #fefefe);
  font-size: 12px;
  font-family: Roboto;
  height: 20px;
}
#partnerCopyRightText {
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
}
@media (max-width: 1000px) {
  .partnerFooter {
    width: 100rem;
    /* margin-top: 1550px; */
  }
  #partnercopyRight {
    width: 100rem;
  }
}
@media (max-width: 1600px) {
  .partnerFooterContent {
    margin-left: 0px;
  }
}
