.usefulLinks {
  margin-top: 42px;
  position: absolute;
  margin-left: 500px;
}
#howItWorksFooter {
  cursor: pointer;
}
#footerLinks:link {
  text-decoration: none;
}
#footerLinks:hover {
  text-decoration: underline;
  color: white;
}
