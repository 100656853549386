#commonHeading {
  color: var(--primary-color-primary, #0052a8);
  font-size: 20px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 32px;
}

#commonSubHeading {
  color: var(--typography-color-t-3, #647e9c);
  font-size: 16px;
  font-family: Roboto;
}
.ImageWithHeadingAndSubHeading {
  display: flex;
  flex-direction: row;
}

.imageLogo {
  margin-right: 34px;
}
