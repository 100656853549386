.cusomterdownloadAppContainer {
  margin-top: 150px;
  border-radius: 12px;
  width: 1296px;
  height: 600px;
  margin-left: 2vw;
  background-image: url("../../../../assets/images/downloadOurApp/downloadAppBackground.svg");
}

#customerdownloadAppHeading {
  color: #fdfdfd;
  font-size: 40px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 32px;
  display: flex;
  width: 337.163px;
  flex-direction: column;
  flex-shrink: 0;
  padding-top: 158px;
  padding-left: 82px;
}

#customerdownloadAppSubHeading {
  color: var(--other-color-alert-actions, #fdfdfd);
  font-size: 20px;
  font-family: Roboto;
  line-height: 40px;
  display: flex;
  width: 546.75px;
  flex-direction: column;
  flex-shrink: 0;
  padding-top: 32px;
  padding-left: 82px;
}

#customerdownloadAppStoreImage {
  width: 198px;
  padding-left: 80px;
  padding-right: 41px;
}
#customerdownloadPlayStoreImage {
  width: 198px;
  /* left: 302px; */
}
.customerdownloadAppImages {
  display: flex;
  padding-top: 40px;
  flex-direction: row;
}

.customerdownloadAppContainer {
  display: flex;
  flex-direction: row;
  justify-self: center;
}
.customerdownloadAppImage {
  margin-left: 765px;
  margin-top: -400px;
  position: relative;
}
@media (max-width: 1000px) {
  .cusomterdownloadAppContainer {
    /* margin-top: -600px; */
    margin-left: 70px;
  }
}
@media (max-width: 1600px) {
  .customerdownloadAppContainer {
    margin-left: 50px;
    margin-top: 0px;
  }
}

/* #downloadAppHeading {
  color: #fdfdfd;
  font-size: 40px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 32px;
  display: flex;
  width: 337.163px;
  flex-direction: column;
  flex-shrink: 0;
  padding-top: 158px;
  padding-left: 82px;
} */
