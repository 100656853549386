#aboutHeading {
  color: #0d386b;
  font-size: 36px;
  font-family: Roboto;
  font-weight: 500;
  /* position: absolute; */
  top: 80px;
  width: 584px;
  left: 40vw;
  text-align: center;
  position: relative;
}
#aboutImage {
  position: absolute;
  top: 138px;
  left: 69vw;
}
#cloudShopText1 {
  top: 174px;
  left: 166px;
  width: 392px;
  position: absolute;
}
#onlineMarkerPlace {
  top: 300px;
  left: 166px;
  width: 392px;
  position: absolute;
}
#verifiedProfessionals {
  top: 430px;
  left: 166px;
  width: 392px;
  position: absolute;
}
#realTimeSearch {
  top: 540px;
  left: 166px;
  width: 392px;
  position: absolute;
}
.About {
  /* z-index: -999; */
}

@media (max-width: 1600px) {
  #aboutHeading {
    left: 428px;
  }
  #aboutImage {
    left: 965px;
  }
}
