#iconHeading {
  color: var(--typography-color-t-2, #395b84);
  font-size: 20px;
  margin-bottom: 8px;
  font-family: Roboto;
  font-weight: 400px;
}

#iconSubHeading {
  color: var(--typography-color-t-3, #647e9c);
  font-size: 14px;
  font-family: Roboto;
  line-height: 20px;
}
.iconWithHeadingAndSubHeading {
  display: flex;
  flex-direction: row;
  width: 344px;
}

.iconLogo {
  margin-right: 16px;
}
