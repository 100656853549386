* {
  margin: 0;
  padding: 0;
}
.section1 {
  z-index: 10; /*When scroll the page,for stopping the background color going on top of navbar */
  height: 574px;
  background: radial-gradient(100% 1056.62% at 0% 0%, #0064cd 0%, #0074ed 100%);
}
canvas {
  display: block;
}

.section1 .wave {
  position: absolute;
  left: 0;
  width: 100%;
  top: 538px;
  background: url(wave.png);
  background-size: 1000px 100px;
}
.section1 .wave.wave1 {
  animation: animate 40s linear infinite;
  height: 37px;
  z-index: 1000;
  opacity: 0.7;
  animation-delay: 10s;
  bottom: 0;
}
@keyframes animate {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 1000px;
  }
}
.section1 .wave.wave2 {
  height: 51px;
  animation: animate2 20s linear infinite;
  /* z-index: 999; */
  opacity: 0.5;
  animation-delay: 5s;
  bottom: 0;
}
@keyframes animate2 {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -1000px;
  }
}
.section1 .wave.wave3 {
  height: 73px;
  animation: animate3 10s linear infinite;
  z-index: 998;
  opacity: 0.3;
  animation-delay: -3s;
  bottom: 0;
}
@keyframes animate3 {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 1000px;
  }
}
.section1 .wave.wave4 {
  height: 96px;
  animation: animate4 12s linear infinite;
  opacity: 0.2;
  animation-delay: -2s;
  bottom: 0;
  background-color: #92bdff;
}
@keyframes animate4 {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -1000px;
  }
}

@media (max-width: 1000px) {
  .section1 {
    height: 574px;
    width: 100rem;
    margin-bottom: 25%;
    background: radial-gradient(
      100% 1056.62% at 0% 0%,
      #0064cd 0%,
      #0074ed 100%
    );
  }
  .section1 .wave {
    width: 100rem;
    background: url(wave.png);
    background-size: 1000px 100px;
  }
}
