#downloadStepHeading {
  color: var(--typography-color-t-2, #395b84);
  font-size: 32px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 8px;
  width: 309px;
}

#downloadStepSubHeading {
  color: var(--typography-color-t-2, #395b84);
  font-size: 20px;
  font-family: Roboto;
  line-height: 32px;
  width: 378px;
}
.circleNumberWithHeadingAndSubHeading {
  display: flex;
  flex-direction: row;
}

.circleWithNumber {
  margin-right: 34px;
}

.circleWithNumberRight {
  margin-left: -125px;
}
