.partnerTrigsySection {
  margin-top: 32px;
  margin-left: 72px;
  position: absolute;
  display: flex;
  flex-direction: column;
}
#partnerTrigsy {
  color: var(--other-color-white, #fefefe);
  font-size: 30px;
  font-family: Jost;
  font-weight: 500;
  text-transform: capitalize;
  margin-left: 10px;
}
.partnerTrigsyLogoAndText {
  display: flex;
  flex-direction: row;
}
#partnerFooterTrigsySubHeading {
  display: flex;
  width: 247px;
  flex-direction: column;
  flex-shrink: 0;
  color: var(--other-color-white, #fefefe);
  font-size: 16px;
  font-family: Roboto;
  line-height: 24px;
  margin-top: 16px;
}

.partnerSocialMediaIcons {
  display: flex;
  flex-direction: row;
  margin-top: 76px;
}
#partnerInstagramId {
  margin-left: 16px;
  margin-right: 16px;
}
#partnerLinkedInId {
  margin-right: 16px;
}
#partnerTwitter {
  margin-right: 16px;
}
