#heading {
  position: absolute;
  width: 687px;
  height: 52px;
  /*  */
  top: 130px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 800;
  font-size: 56px;
  line-height: 66px;
  color: #fefefe;
}

#content {
  position: absolute;
  width: 725px;
  height: 120px;
  top: 265px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  color: #fefefe;
}
#subHeading {
  position: absolute;
  width: 725px;
  height: 40px;
  top: 200px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #fefefe;
}

#partnerPlayStoreImage {
  position: absolute;
  top: 450px;
  width: 198px;
  /* left: 230px; */
}
#comingSoonText {
  color: white;
  font-size: 16px;
  text-align: center;
}
#rightSideIcon {
  left: 60vw;
  top: 100px;
  position: absolute;
}

.homeContent {
  display: flex;
  left: 5vw;
  position: absolute;
}

@media (max-width: 1600px) {
  #rightSideIcon {
    left: 950px;
  }
  .homeContent {
    left: 72px;
  }
}
