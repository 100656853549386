.partnerLetTryItOut {
  margin-top: 42px;
  position: absolute;
  margin-left: 1200px;
}
#partnerFooterDownloadAppStoreImage {
  margin-top: 26px;
  margin-bottom: 20px;
}
#partnerFooterAppStoreImage {
  position: relative;
}
#partnerFooterPlayStoreImage {
  position: relative;
  margin-bottom: 20px;
}
