.iconWithSubheading {
  display: flex;
  flex-direction: row;
  margin-top: 22px;
}
#footerHelpSupportText {
  color: var(--other-color-white, #fefefe);
  font-size: 16px;
  font-family: Roboto;
  margin-left: 8px;
  text-decoration: none;
}
#footerHelpSupportText:hover{
color: white;
text-decoration: underline;
}