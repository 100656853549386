.footer {
  width: 100%;
  height: 285px;
  margin-top: 50px;
  background: var(--primary-color-primary, #0052a8);
  position: relative;
  z-index: 10;
}
.footerContent {
  margin-left: 10vw;
}
#copyRight {
  position: relative;
  background: var(--primary-color-primary-color-2, #2e70b4);
  width: 100%;
  color: var(--other-color-white, #fefefe);
  font-size: 12px;
  font-family: Roboto;
  height: 20px;
}
#copyRightText {
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
}
@media (max-width: 1000px) {
  .footer {
    width: 100rem;
    /* margin-top: 1550px; */
  }
  #copyRight {
    width: 100rem;
  }
}
@media (max-width: 1600px) {


  .footerContent {
    margin-left: 0px;
  }
}
