#downloadImage3 {
  position: absolute;
  top: 780px;
  left: 33vw;
}
#enjoyTheApp {
  position: absolute;
  top: 850px;
  left: 55vw;
}
@media (max-width: 1600px) {
  #downloadImage3 {
    position: absolute;
    top: 780px;
    left: 386px;
  }
  #enjoyTheApp {
    position: absolute;
    top: 850px;
    left: 788px;
  }
}
