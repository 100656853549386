#downloadImage2 {
  position: absolute;
  left: 55vw;
  top: 508px;
}
#createAccount {
  position: absolute;
  left: 31vw;
  top: 608px;
}
@media (max-width: 1600px) {
  #downloadImage2 {
    position: absolute;
    left: 789px;
    top: 508px;
  }
  #createAccount {
    position: absolute;
    left: 366px;
    top: 608px;
  }
}
