#howItWorksHeading {
  color: var(--typography-color-t-1, #0d386b);
  text-align: center;
  font-size: 36px;
  font-family: Roboto;
  font-weight: 500;
  position: relative;
  /* top: 140px; */
  top: 100px;
  left: 45vw;
  width: 241px;
}
#howItWorksBodyImagediv {
  margin-top: 150px;
  /* left: 12vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 36vw; */
}
#howItWorksImage {
  height: 900px;
}
@media (max-width: 1600px) {
  #howItWorksHeading {
    left: 600px;
  }
  #howItWorksBodyImage {
    margin-left: 1000px;
  }
}
